import { ChatAppResponse } from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
};

export function parseAnswerToHtml(answer: ChatAppResponse, isStreaming: boolean): HtmlParsedAnswer {
    // Trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.message.content.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    return {
        answerHtml: parsedAnswer
    };
}
