import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";

import DOMPurify from "dompurify";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import styles from "./Answer.module.css";
import { ChatAppResponse } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { useTranslation } from "react-i18next";
import BotIcon from "../Icons/BotIcon";

interface Props {
    answer: ChatAppResponse;
    index: number;
    isSelected?: boolean;
    isStreaming: boolean;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    index,
    isSelected,
    isStreaming,
    onFollowupQuestionClicked,
    showFollowupQuestions,
}: Props) => {
    const followupQuestions = answer.context?.followup_questions;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer, isStreaming), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const { t, i18n } = useTranslation();

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                <BotIcon className={styles.chatCenterImage} aria-hidden="true" aria-label="Chat logo"  />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title={t("tooltips.showThoughtProcess")}
                            ariaLabel={t("tooltips.showThoughtProcess")}
                            disabled={!answer.context.thoughts?.length}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title={t("tooltips.showSupportingContent")}
                            ariaLabel={t("tooltips.showSupportingContent")}
                            disabled={!answer.context.data_points}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText}>
                    <ReactMarkdown children={sanitizedAnswerHtml} remarkPlugins={[remarkGfm]} />
                </div>
                {/* <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div> */}
            </Stack.Item>
        </Stack>
    );
};
