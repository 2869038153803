const BACKEND_URI = import.meta.env.VITE_BACKEND_URI
console.log("Say hello to ", BACKEND_URI)

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, SimpleAPIResponse } from "./models";

export async function getHeaders(idToken: string | undefined): Promise<Record<string, string>> {
    return {
        Authorization: `Bearer ${idToken}`
    };
}

export async function configApi(): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET"
    });

    return (await response.json()) as Config;
}

export async function chatApi(request: ChatAppRequest, shouldStream: boolean, idToken: string | undefined): Promise<Response> {
    let url = `${BACKEND_URI}/chat`;
    let response_content = null
    if (shouldStream) {
        url += "/stream";
    }

    const headers = await getHeaders(idToken);

    response_content = await fetch(url, {
        method: "POST",
        headers: { ...headers, "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });

    return response_content
}

