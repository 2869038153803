import React from 'react';
import myIcon from '../../assets/bot-icon.png';

const BotIcon = (props: { className: string | undefined; }) => {
    return (
        <div className={props.className}>
            <img 
                alt="AIHana Icon"
                src={myIcon}
                width={64}
                height={64}
                style={{objectFit: 'contain'}}
            />
        </div>
    )
}

export default BotIcon;