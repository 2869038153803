import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import { I18nextProvider } from "react-i18next"

import Layout from "./pages/layout/Layout";
import ContactPage from "./pages/contact/contact";
import SignInPage from "./pages/auth/sign-in";
import SignUpPage from "./pages/auth/sign-up";

var layout = <Layout/>

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
}

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: layout,
        children: [
            { path: '/contact', element: <ContactPage /> },
            { path: '/sign-in/*', element: <SignInPage /> },
            { path: '/sign-up/*', element: <SignUpPage /> },
            {
                path: "*",
                lazy: () => import("./pages/Empty")
            }
        ]
    }
]);

import i18next from "./i18n/config";
// import IndexPage from "./pages/auth";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <I18nextProvider i18n={i18next}>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </I18nextProvider>
);
