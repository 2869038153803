import { Outlet, Link, useNavigate } from "react-router-dom";
import { ClerkProvider, SignedIn, SignedOut, SignIn, useClerk, UserButton } from '@clerk/clerk-react'

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

import styles from "./Layout.module.css";
import { useState } from "react";
import SignInPage from "../auth/sign-in";
import Chat from "../chat/Chat";

const Layout = () => {
    const navigate = useNavigate()
    const [loggedIn, setLoggedIn] = useState(false)
    const [email, setEmail] = useState('')
    
    return (
        <ClerkProvider
            routerPush={(to) => navigate(to)}
            routerReplace={(to) => navigate(to, { replace: true })}
            publishableKey={PUBLISHABLE_KEY}
            afterSignOutUrl="/"
        >
            <div className={styles.layout}>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h2 className={styles.headerLogoImage}>test</h2>
                            <h3 className={styles.headerTitle}>HanaAI</h3>
                        </Link>
                        {/* <h4 className={styles.headerRightText}>Chat demo</h4> */}
                        <div>
                            <SignedIn>
                                <UserButton />
                            </SignedIn>
                        </div>
                    </div>
                </header>
                {/* <HomePage/> */}
                <main className={styles.main} role={"main"}>
                    <div className={styles.mainContainer}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <SignedOut>
                                <SignInPage />
                            </SignedOut>
                        </div>
                        <SignedIn>
                            <Chat />
                        </SignedIn>
                    </div>
                </main>
                <Outlet />
            </div>
        </ClerkProvider>
    );
};

export default Layout;
